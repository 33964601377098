<template>
  <div class="filter-item">
    <div class="filterItem-label">{{ label || "" }}</div>
    <div
      class="filterItem-content"
      :class="{
        'filterItem-content--fold': fold && !unfold,
        'filterItem-content--unfold': fold && unfold,
      }"
    >
      <slot>
        <el-checkbox-group v-model="checkList" size="medium" :border="false">
          <el-checkbox-button
            :label="index"
            v-for="(item, index) in cpu_list"
            :key="index"
            @change="selectItem(item)"
            class="content-item"
          >
            {{ item[labelKey] }}
          </el-checkbox-button>
        </el-checkbox-group>
        <slot name="append"></slot>
      </slot>
    </div>
    <div
      v-if="fold"
      class="filterItem-right"
      :class="{ 'filterItem-right--unfold': unfold }"
      @click="toggleFold"
    >
      <span>{{ unfold ? "收起" : "更多" }}</span>
      <i class="el-icon-caret-bottom icon"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterCheckBox",
  props: {
    value: {},
    label: {
      type: String,
      default: "",
    },
    checkLists: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    // 是否需要不限
    needAll: {
      type: Boolean,
      default: true,
    },
    valueKey: {
      type: String || Number,
      default: "value",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    // 是否开启展开折叠
    fold: {
      type: Boolean,
      default: false,
    },
    type:{
      type: String || Number,
      default: '0',
    }
  },
  data() {
    return {
      unfold: false, // 展开状态位
      checkList:[],
    };
  },
  computed: {
    cpu_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    cpu_list() {
      if (this.needAll) {
        return [
          {
            [this.valueKey]: "",
            [this.labelKey]: "不限",
          },
        ].concat(this.list);
      } else {
        return this.list;
      }
    },
    cpu_isActive() {
      return function (item) {
        return item[this.valueKey] == this.value;
      };
    },
  },
  created(){
    if(this.type == '1'){
      let item = {};
      item.label = '不限';
      this.selectItem(item);
    }
  },
  methods: {
    cleanItem(){
      this.checkList = [];
    },
    /**
     * @description: 选择子项
     * @param {Object} item 子项
     */
    selectItem(item) {
      // 选择不限之后，其他标签取消选中
      if(item.label=="不限" || item.deptName=="不限"){
        this.checkList = [];
        this.checkList.push(0);
      }else{
        // 如果再选其他标签，不限标签取消选中
        let index = this.checkList.findIndex(item=>{
          return item==0
        });
        if(index!=-1){
          this.checkList.splice(index,1)
        }
      }
      let checkvalue = [];
      if (this.checkList.length > 0) {
        this.checkList.forEach((item) => {
          checkvalue.push(this.cpu_list[item][this.valueKey]);
        });
          checkvalue = checkvalue.join();
      }
      else{
        checkvalue=''
      }
      this.$nextTick(() => {
        this.$parent.$emit("change", this.label, checkvalue);
      });
    },
    /**
     * @description: 切换展开折叠状态
     */
    toggleFold() {
      this.unfold = !this.unfold;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

$itemHeight: 60px;
::v-deep .el-checkbox-button--medium .el-checkbox-button__inner {
    border: none;
    padding: 6px 10px !important;
    margin: auto 5px;
    font-size: 18px;
    border-radius: 4px !important;
}
.filter-item {
  display: -webkit-flex;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}
.filterItem-label,
.content-item,
.filterItem-right {
  height: $itemHeight;
  line-height: $itemHeight;
  span,
  i{
    color: #409eff;
  }
}
.filterItem-label {
  min-width: 80px;
  color: #000000;
}
.filterItem-content {
  flex: 1;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding-left: 20px;
  overflow: hidden;

  .content-item {
    display: inline-block;
    // padding-right: 10px;
    // padding-left: 10px;
    cursor: pointer;

    &:hover {
      color: $col-theme;
    }
    &.content-item--active {
      border-radius: 4px;
      color: #ffffff;
      background-color: $col-theme;
    }
  }
  &.filterItem-content--fold {
    height: $itemHeight;
  }
}
.filterItem-right {
  color: #000;
  margin-left: 20px;
  cursor: pointer;

  .icon {
    margin-left: 5px;
    -webkit-transition: transform 0.2s linear 0s;
    -moz-transition: transform 0.2s linear 0s;
    -o-transition: transform 0.2s linear 0s;
    transition: transform 0.2s linear 0s;
  }
  &.filterItem-right--unfold .icon {
    transform: rotate(180deg);
  }
}
</style>
