<template>
    <div class="filter-group">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'FilterGroup',
}
</script>

<style lang="scss" scoped>

.filter-item {
    // width: 1200px;
    padding:0 20px;
    margin:0 auto;
    background-color: #fff;
    // margin-left:-100px;
}
.filter-item + .filter-item {
    border-top: 1px dashed #eeeeee;
}
</style>